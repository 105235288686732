<template>
  <div>
    <div v-if="processResultStatus === 'PendingForApproval'">
      <div style="margin-right: 20px; margin-left: 20px">
        <el-alert
          :title="getRes('System.Message.Error.UpdateCompanyNameUnderApproval')"
          type="error"
          :closable="false"
        />
      </div>

      <pro-form-render
        mode="View"
        :code="workFlowCodeView"
        :form-data-promise="formDataPromise"
      />
    </div>

    <pro-form-render
      v-else
      mode="Edit"
      :code="workFlowCode"
      :form-data-promise="formDataPromise"
      @pro-form-submit="redirectToHcmDashboard()"
    />
  </div>
</template>

<script>
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  name: "SupplierUpdateCompanyName",
  components: { ProFormRender },

  data() {
    return {
      workFlowCode: "S_E_CompanyName",
      workFlowCodeView: "Supplier_Edit_CompanyName_View",
      formDataPromise: null,
      basicInfo: null,
      basicInfoPromise: null,
      processResultStatus: null,
    };
  },

  created() {
    this.getBasicInfoPromise();
    this.$proSmart.supplierUi
      .getSupplierUpdateCompanyNameProcessInfo(this)
      .then((info) => {
        const { processResultStatus, cmpyNameEng, cmpyNameChi } = info;
        this.processResultStatus = processResultStatus;

        switch (processResultStatus) {
          case "PendingForApproval":
            this.formDataPromise = Promise.resolve({
              cmpyNameEng,
              cmpyNameChi,
              cmpyNameEngPre: this.basicInfo.cmpyNameEng,
              cmpyNameChiPre: this.basicInfo.cmpyNameChi,
            });
            this.loading = false;
            break;
          case "Created":
          default:
            this.formDataPromise = Promise.resolve(this.basicInfoPromise);
            this.loading = false;
            break;
        }
      });
  },

  methods: {
    redirectToHcmDashboard() {
      const path = "/UpdateCompanyName";
      this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: "/Dashboard" });
    },
    getBasicInfoPromise() {
      this.basicInfoPromise = this.$proSmart.supplierUi
        .getBasicInfo(this)
        .then((basicInfo) => {
          this.basicInfo = basicInfo;
          return {
            ...basicInfo,
            cmpyNameEngPre: basicInfo.cmpyNameEng,
            cmpyNameChiPre: basicInfo.cmpyNameChi,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
